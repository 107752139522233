import TableCell from '@material-ui/core/TableCell';
import API, {
  GetFieldsPathForApi,
  GetUserOrganizationProjectDocumentFolderFieldsPathForApi,
  GetUserOrganizationProjectApprovalTaskFieldsPathForApi,
  GetUserOrganizationProjectTaskFieldsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskFieldsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemProjectFieldsPathForApi,
} from './api';
import {
  GetProjectFieldPath,
} from './PathHelper';
import {
  GetFieldForFilterAndSort,
} from './Field';
import {
  GetBoolValue,
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  // GetTaskLabelByResult,
  GetTaskPriorityLabel,
  // GetTaskDurationAsString,
} from '../Util/Task';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';

export const HandleUpdateFields = (organizationId, projectId, Fields, onApiError) => {
  return API.put(GetFieldsPathForApi(organizationId, projectId), Fields)
    .then(resp => {})
    .catch(onApiError);
}

export const DeleteFields = (organizationId, projectId, IDs) => {
  return API.delete(GetFieldsPathForApi(organizationId, projectId), { data: { IDs } });
}

export const HandleRouteToFieldDialog = (props, projectId, fieldId) => {
  props.history.push(GetProjectFieldPath(projectId, fieldId), 
    { ...props.location.state });
}

export const GetCardMetadataFromPreviewMetadata = (previewMetadata, sensitiveFields) => {
  let metadata = [];
  if (previewMetadata && previewMetadata.length) {
    previewMetadata.sort(compareMetadata).forEach(field => {
      if (!sensitiveFields || sensitiveFields.filter(sf => sf.ID === field[0]).length === 0) {
        metadata.push([field[1], field[2]]);
      }
    });
  }
  return metadata;
}

const compareMetadata = (a, b) => {
  if (a[1] < b[1]) {
    return -1;
  }
};

export const AddFilterSortFieldsToCollectionFields = (collectionFields, filterSortFields) => {
  if (collectionFields && filterSortFields) {
    filterSortFields.forEach(f => {
      collectionFields.push(
        GetFieldForFilterAndSort(
          f.SortID,
          f.Field.LabelOrName,
          f.Field.Type,
          f.FilterID,
        ),
      );
    });
  }
}

export const AddFilterSortFieldsToHeadCells = (headCells, filterSortFields) => {
  if (headCells && filterSortFields) {
    if (filterSortFields.length) {
      filterSortFields.forEach(f => {
        headCells.push(
          {
            id: f.FilterID || f.SortID,
            sortId: f.SortID,
            numeric: (f.Field.Type === "FieldType_Number" || f.Field.Type === "FieldType_Currency"),
            label: f.Field.LabelOrName,
            ItemField: f.Field.ItemField,
            Editable: f.Field.Editable,
            CellEditorSelector: f.Field.CellEditorSelector,
            CellDataType: f.Field.CellDataType,
            CellRenderer: f.Field.CellRenderer,
            PreviewMetadataFieldID: f.Field.PreviewMetadataFieldID,
          },
        );
      });
    }
  }
}

export const GetItemTableCellsForFilterSortFields = (item, filterSortFields, classes, formatFirstColumn, sortType, sortDescending) => {
  let tableCells = [];
  if (item && filterSortFields) {
    filterSortFields.forEach((f, fIndex) => {
      let addlProps = {};
      if (fIndex === 0 && formatFirstColumn) {
        addlProps = {
          ...addlProps,
          component: "th",
          id: `label_${item.ID}`,
          scope: "row",
          padding: "none",
        };
      }
      if (f.Field.Type === "FieldType_Number" || f.Field.Type === "FieldType_Currency") {
        addlProps = {
          ...addlProps,
          align: "right",
        };
      }
      let fieldValue;
      const previewFieldFinder = (item.PreviewMetadata && item.PreviewMetadata.length)
        ? item.PreviewMetadata.filter(p => p[0] === f.Field.ID)
        : null;
      if (previewFieldFinder && previewFieldFinder.length) {
        const previewField = previewFieldFinder[0];
        const previewValue = previewField[2];
        switch (f.Field.Type) {
          case "FieldType_Bool":
            fieldValue = GetBoolValue(previewValue === "true");
            break;
          default:
            fieldValue = previewValue;
            break;
        }
      } else {
        // Metafields
        switch (f.SortID) {
        case "Meta_text_kw256lc[Name].keyword":
          fieldValue = item.Name;
          break;
        case "Meta_text_kw256lc[AssignmentUserEmail].keyword":
          fieldValue = GetUserValue(item.AssignmentUserEmail, item.AssignmentUserName);
          break;
        case "Meta_date_str256[DueOn].string":
          fieldValue = GetDateValue(item.DueOn);
          break;
        case "Meta_text_kw256lc[MilestoneName].keyword":
          fieldValue = item.TaskMilestoneName;
          break;
        case "Meta_text_kw256lc[StateName].keyword":
          fieldValue = item.TaskStateName;
          break;
        case "Meta_int[Priority]":
          fieldValue = GetTaskPriorityLabel(item.Priority);
          break;
        case "Meta_text_kw50lc[Tag].keyword":
          fieldValue = GetTagsPreviewFromTagsObject(item, 
            sortType === "Meta_text_kw50lc[Tag].keyword" && sortDescending);
          break;
        default:
          break;
        }
      }
      tableCells.push(
        <TableCell {...addlProps} key={f.Field.ID || f.ID || f.SortID}
          className={classes.tableCell}>{fieldValue}</TableCell>
      );
    });
  }
  return tableCells;
}

export const GetFieldsFirstPagePromise = (organizationId, projectId, extraParams) => {
  return getFieldsInternalPromise(organizationId, projectId, {}, extraParams);
}

export const GetAllFieldsPromise = (organizationId, projectId, extraParams, documentFolderIdForDocumentFolderAssignmentContext,
  taskIdForTaskAssignmentContext, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext) => {
  const params = {
    getAll: true
  };
  return getFieldsInternalPromise(organizationId, projectId, params, extraParams, documentFolderIdForDocumentFolderAssignmentContext,
    taskIdForTaskAssignmentContext, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext);
}

const getFieldsInternalPromise = (organizationId, projectId, params, extraParams, documentFolderIdForDocumentFolderAssignmentContext,
  taskIdForTaskAssignmentContext, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext) => {
  if (extraParams) {
    params = Object.assign(params, extraParams);
  }

  let uri;
  if (documentFolderIdForDocumentFolderAssignmentContext) {
    uri = GetUserOrganizationProjectDocumentFolderFieldsPathForApi(organizationId, projectId,
      documentFolderIdForDocumentFolderAssignmentContext);
  } else if (approvalIdForApprovalAssignmentContext) {
    if (assetItemForAssetItemContext) {
      if (taskIdForTaskAssignmentContext) {
        uri = GetUserOrganizationProjectApprovalAssetItemTaskFieldsPathForApi(organizationId, projectId, 
          approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID, assetItemForAssetItemContext.ID,
          taskIdForTaskAssignmentContext);
      } else {
        uri = GetUserOrganizationProjectApprovalAssetItemProjectFieldsPathForApi(organizationId, projectId,
          approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID, assetItemForAssetItemContext.ID);
      }
    } else if (taskIdForTaskAssignmentContext) {
      uri = GetUserOrganizationProjectApprovalTaskFieldsPathForApi(organizationId, projectId, 
        approvalIdForApprovalAssignmentContext, taskIdForTaskAssignmentContext);
    }
  } else if (taskIdForTaskAssignmentContext) {
    uri = GetUserOrganizationProjectTaskFieldsPathForApi(organizationId, projectId, taskIdForTaskAssignmentContext);
  } else {
    uri = GetFieldsPathForApi(organizationId, projectId);
  }

  return API.get(uri, { params });
}

/**
 * This is a generalized method for recursing parent/child fields top-down, based on a field's ParentFieldID property.
 * This method is the logical opposite of recurseParentFields.
 * 
 * @param {Object} parentField - The top-level, parent field.
 * @param {Array} allFields - All available fields to evaluate for a child relationship for the given field.
 * @param {Function(Object):string} fieldIdGetter - A method called with a field to obtain its field ID.
 * @param {Function} [additionalRecursionCriteriaHandler] - An optional method to be called when evaluating a field.
 * @param {Function} dependentFieldActionHandler - A method to call when a dependent child field is encountered.
 */
const recurseChildFields = (parentField, allFields, fieldIdGetter, parentFieldIdGetter,
  additionalRecursionCriteriaHandler, dependentFieldActionHandler) => {
  // Safety mechanism to prevent infinite recursion
  const processedFieldIDs = new Set();

  const recurseChildFieldsInternal = (currentField, previousField) => {
    const currentFieldFieldId = fieldIdGetter(currentField);
    if (processedFieldIDs.has(currentFieldFieldId)) return;
    processedFieldIDs.add(currentFieldFieldId);

    // Check for the presence of additionalRecursionCriteriaHandler and call it
    if (additionalRecursionCriteriaHandler?.(currentField, previousField) !== false) {
      if (!allFields) return;

      const childFields = allFields.filter(field => (field.SelectionListIsDependent || (field.Field && field.Field.SelectionListIsDependent))
        && parentFieldIdGetter(field) === currentFieldFieldId);
      for (const childField of childFields) {
        dependentFieldActionHandler(childField);
        recurseChildFieldsInternal(childField, currentField);
      }
    }
  };

  if (!parentField || !dependentFieldActionHandler) return;
  recurseChildFieldsInternal(parentField, null);
}

/**
* This is a generalized method for recursing child/parent fields bottom-up, based on a field's FieldID property.
* This method is the logical opposite of recurseChildFields.
* 
* @param {Object} childField - The bottom-level, child field.
* @param {Array} allFields - All available fields to evaluate for a parent relationship for the given field.
* @param {Function(Object):string} fieldIdGetter - A method called with a field to obtain its field ID.
* @param {Function} [additionalRecursionCriteriaHandler] - An optional method to be called when evaluating a field.
* @param {Function} dependentFieldActionHandler - A method to call when a dependent parent field is encountered.
*/
const recurseParentFields = (childField, allFields, fieldIdGetter, additionalRecursionCriteriaHandler, dependentFieldActionHandler) => {
  // Safety mechanism to prevent infinite recursion
  const processedFieldIDs = new Set();

  const recurseParentFieldsInternal = (currentField, previousField) => {
    const currentFieldFieldID = fieldIdGetter(currentField);
    if (processedFieldIDs.has(currentFieldFieldID)) return;
    processedFieldIDs.add(currentFieldFieldID);

    // Check for the presence of additionalRecursionCriteriaHandler and call it
    if (additionalRecursionCriteriaHandler?.(currentField, previousField) !== false) {
      if (!allFields) return;

      const parentField = allFields.find(field => fieldIdGetter(field) === currentField.ParentFieldID);
      if (parentField) {
        dependentFieldActionHandler(parentField);
        recurseParentFieldsInternal(parentField, currentField);
      }
    }
  };

  if (!childField || !dependentFieldActionHandler) return;
  recurseParentFieldsInternal(childField, null);
}


export const RecurseDependentSelectionListsUpToNonDependentParent = (childField, allFields, fieldIdGetter, action) => {
  const additionalRecursionCriteriaHandler = (parentField, prevChildField) => {
    if (parentField.DisplaySelectionList) {
      // If the field itself is dependent then it should be evaluated
      if (parentField.SelectionListIsDependent) {
        return true;
      }
      // If the child field is dependent then this parent should be evaluated as the last step
      // in the recursion
      if (prevChildField?.SelectionListIsDependent) {
        return true;
      }
    }
    return false;
  }

  recurseParentFields(childField, allFields, fieldIdGetter, additionalRecursionCriteriaHandler, action);
}


export const RecurseDependentSelectionListsDown = (parentField, allFields, fieldIdGetter, parentFieldIdGetter, action) => {
  const additionalRecursionCriteriaHandler = undefined;
  
  recurseChildFields(parentField, allFields, fieldIdGetter, parentFieldIdGetter, additionalRecursionCriteriaHandler, action);
}
