import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import AsyncSelectControl from './AsyncSelectControl';
import { GetAddressBookItemsPromise } from '../Util/AddressBookItems';
import { GetUserValue } from '../Util/Properties';
import debounce from 'es6-promise-debounce';

const TaskAssignmentControl = ({
  task,
  isWorkspace,
  isWorkflow,
  isProjectAdmin,
  isUserRestrictedToAssignedTasks,
  additionalTaskPropertiesForCreation,
  onAssignmentValueChange,
  onAssignmentCreateOption,
  onApiError,
  organizationId,
  projectId,
  forcedBackgroundColor,
}) => {
  const [lastAddressBookItems, setLastAddressBookItems] = useState([]);
  const [taskForAssignmentLabel, setTaskForAssignmentLabel] = useState(task);

  const setAddressListValues = useCallback((addressBookItems, valueToMatch) => {
    // console.log("setAddressListValues valueToMatch", valueToMatch);
    const prevTask = {...taskForAssignmentLabel};
    if (!prevTask) {
      return;
    }
    if (!valueToMatch) {
      valueToMatch = prevTask.AssignmentUserEmail;
    }
    // console.log("prevTask", prevTask.AssignmentUserEmail);
    const updatedTask = { ...prevTask };
    const matching = addressBookItems.find(abi => abi.value === valueToMatch);
    if (matching) {
      // console.log("matching", matching.plainLabel, matching.value);
      updatedTask.AssignmentUserEmail = matching.value;
      updatedTask.AssignmentUserName = matching.label;
    } else {
      // console.log("no match", valueToMatch);
      updatedTask.AssignmentUserEmail = valueToMatch;
      updatedTask.AssignmentUserName = "";
    }
    // console.log("updatedTask", updatedTask.AssignmentUserEmail);
    setTaskForAssignmentLabel(updatedTask);
  }, [taskForAssignmentLabel]);

  const _getAddressBookItemsPromise = useMemo(
    () => debounce(filter => {
      return GetAddressBookItemsPromise(organizationId, projectId, 
        true, !isWorkspace, isWorkflow, isWorkflow, 
        filter)
        .then(items => {
          const addressBookItems = items.map(abi => { 
            if (abi.ProjectMemberID) {
              if (isWorkflow) {
                return ({
                  value: `ProjectMemberID:${abi.ProjectMemberID}`,
                  name: abi.Name,
                  plainLabel: `${abi.EmailLower} (member${(abi.Name) ? " " + abi.Name : ""})`,
                  label: GetUserValue(abi.EmailLower,
                    (abi.Name)
                      ? `${abi.Name} (member ${abi.EmailLower})` 
                      : `${abi.EmailLower} (member)`,
                    "", false, undefined, {}, {}, true,
                    ),
                });
              } else {
                return ({
                  value: abi.EmailLower,
                  name: abi.Name,
                  plainLabel: `${abi.EmailLower} (member)`,
                  label: GetUserValue(abi.EmailLower,
                    (abi.Name) 
                      ? `${abi.Name} (member)` 
                      : `${abi.EmailLower} (member)`,
                    "", false, undefined, {}, {}, true,
                    ),
                });
              }
            } else if (abi.FieldID) {
              return ({
                value: `FieldID:${abi.FieldID}`,
                name: abi.Name,
                label: `${abi.Name} (field)`,
              }); 
            } else if (abi.FormTemplateID && abi.FormTemplateFieldID) {
              return ({
                value: `FormTemplateID:${abi.FormTemplateID} FormTemplateFieldID:${abi.FormTemplateFieldID}`,
                name: abi.Name,
                label: `${abi.Name} (form field, ${abi.FormTemplateName})`,
              }); 
            } else {
              return ({
                value: abi.EmailLower,
                name: abi.Name,
                plainLabel: abi.EmailLower + ((abi.Name) ? " (" + abi.Name +")" : ""),
                label: GetUserValue(abi.EmailLower,
                  (abi.Name)
                    ? abi.Name
                    : abi.EmailLower,
                  "", false, undefined, {}, {}, true,
                  ),
              });
            }
          });
          setAddressListValues(addressBookItems);
          setLastAddressBookItems(addressBookItems);
          return addressBookItems;
        })
        .catch(onApiError);
    }, 250), [organizationId, projectId, isWorkspace, isWorkflow, onApiError, setAddressListValues]);

  const handleGetAddressBookItemsPromise = useCallback(
    _getAddressBookItemsPromise, [_getAddressBookItemsPromise]
  );

  const handleAssignmentCreateOption = value => {
    onAssignmentCreateOption(value, handleAssignmentValueChange);
  }

  const handleAssignmentValueChange = useCallback((selectedOption) => {
    const originalAssignmentUserEmail = taskForAssignmentLabel.AssignmentUserEmail;
    setAddressListValues(lastAddressBookItems, selectedOption.value);
    onAssignmentValueChange(selectedOption)
      .catch(err => {
        setAddressListValues(lastAddressBookItems, originalAssignmentUserEmail);
      });
  }, [taskForAssignmentLabel, lastAddressBookItems, setAddressListValues, onAssignmentValueChange]);

  const taskIsInactive = (!isWorkflow && task && task.Result);

  const assignmentDisabled = taskIsInactive
    || isWorkspace
    || (isUserRestrictedToAssignedTasks && !isProjectAdmin)
    || (additionalTaskPropertiesForCreation
      && additionalTaskPropertiesForCreation.AssignmentUserEmail
      && additionalTaskPropertiesForCreation.AssignmentUserEmail !== "");

  const selectedAssignmentOption = 
    (taskForAssignmentLabel && taskForAssignmentLabel.AssignmentUserEmail)
      ? { 
        label: taskForAssignmentLabel.AssignmentUserName || taskForAssignmentLabel.AssignmentUserEmail,
        value: taskForAssignmentLabel.AssignmentUserEmail,
      }
      : (task && task.AssignmentUserEmail)
        ? { 
          label: GetUserValue(task.AssignmentUserEmail, task.AssignmentUserName),
          value: task.AssignmentUserEmail,
        }
        : null;

  const assignmentControl = (
    <AsyncSelectControl 
      label="Assigned to" 
      noFlexWrap
      floatingOptions
      style={{ backgroundColor: forcedBackgroundColor }}
      onGetOptionsFilterPromise={handleGetAddressBookItemsPromise}
      listValues={selectedAssignmentOption}
      onValueChange={handleAssignmentValueChange}
      onCreateOption={handleAssignmentCreateOption}
      autoReloadOnValueChange
      notClearable
      disabled={assignmentDisabled}
    />
  );

  return (isWorkspace && assignmentDisabled)
    ? (
      <Tooltip title="Assignments cannot be changed in My Work.">
        {assignmentControl}
      </Tooltip>
    )
    : assignmentControl;
};

TaskAssignmentControl.propTypes = {
  task: PropTypes.object,
  isWorkspace: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  isUserRestrictedToAssignedTasks: PropTypes.bool,
  additionalTaskPropertiesForCreation: PropTypes.object,
  onAssignmentValueChange: PropTypes.func.isRequired,
  onAssignmentCreateOption: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  forcedBackgroundColor: PropTypes.string,
};

export default TaskAssignmentControl;
