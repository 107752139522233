import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  miniRoot: {
    "& .MuiOutlinedInput-input": {
      padding:8,
      marginRight:24,
    },
    "& .MuiFormControl-root": {
    },
  },
  formControl: {
    // margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class SelectControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      IsFocused: false,
    }
  }

  componentDidMount() {
  }

  showBeforeChangeConfirmationMessage = (message) => {
    return window.confirm(message);
  };

  render() {
    const {
      IsFocused,
    } = this.state;
    const { 
      classes,
      label,
      value,
      forceShrinkLabel,
      onValueChange,
      onKeyDown,
      hideEmpty,
      emptyLabel,
      options,
      helperText,
      showTooltipForLabel,
      focused,
      disabled,
      fontSize,
      size,
      id,
      tabIndex,
      useMiniStyle,
      confirmationMessageBeforeValueChange,
      style,
    } = this.props;

    let menuItems = [];
    if (!hideEmpty) {
      menuItems.push(
        <MenuItem key="none" value="">
          <em>{emptyLabel || "None"}</em>
        </MenuItem>
      );
    }

    // props.options must be array of objects 
    // { label: <string>, value: <string>, [optional]backgroundColor: color }
    options.forEach(o => {
      menuItems.push(
        <MenuItem
          key={o.key || o.value} 
          value={o.value}
          style={{
            color: (o.color) ? o.color : undefined,
            backgroundColor: (o.backgroundColor) ? o.backgroundColor : undefined,
            fontFamily: (o.fontFamily) ? o.fontFamily : undefined,
          }}
        >
          {o.label}
        </MenuItem>
      );
    });

    let selectedForegroundColor;
    let selectedBackgroundColor;
    let selectedOption = null;
    let selectedOptionFinder = options.filter(o => o.value === value);
    if (selectedOptionFinder && selectedOptionFinder.length) {
      selectedOption = selectedOptionFinder[0];
      selectedForegroundColor = selectedOption.color;
      selectedBackgroundColor = selectedOption.backgroundColor;
    }

    let helperTextComponent = (helperText)
      ? (<FormHelperText>{helperText}</FormHelperText>) : null;
    const hasLabel = forceShrinkLabel
      || IsFocused
      || (value !== undefined && value.length > 0);
    let formControl = (
      <FormControl
        fullWidth
        size={size}
        focused={focused}
        variant="outlined" 
        disabled={disabled}
        className={classes.formControl}
        onKeyDown={e => (onKeyDown) ? onKeyDown(e) : null}>
        <InputLabel
          htmlFor="outlined-select"
          shrink={forceShrinkLabel}
        >
          {label}
        </InputLabel>
        <Select
          style={style}
          value={(value !== undefined && value !== null) ? value : ""}
          // onFocus={() => this.setState({IsFocused: true})}
          // onBlur={() => this.setState({IsFocused: false})}
          onChange={
            e => {
              const confirmMsg = confirmationMessageBeforeValueChange;
              if (confirmMsg && !this.showBeforeChangeConfirmationMessage(confirmMsg)) {
                return;
              }
              onValueChange(e.target.value);
            }
          }
          input={
            <OutlinedInput
              style={{
                fontSize:(fontSize) ? fontSize : undefined,
                color: selectedForegroundColor,
                backgroundColor:selectedBackgroundColor,
              }}
              inputProps={{
                tabIndex,
              }}
              label={(hasLabel) ? label : null}
              notched={hasLabel}
              name={id || "field"}
              id={id || "outlined-select"}
            />
          }
          IconComponent={selectedForegroundColor ? (props => 
            (
              <ArrowDropDownIcon
                className={classNames("MuiSelect-icon", "MuiSelect-iconOutlined")}
                style={{
                  fill: (selectedForegroundColor),
                }}
                viewBox="0 0 24 24"
              />
            ))
            : undefined
          }
        >
          {menuItems}
        </Select>
        {helperTextComponent}
      </FormControl>
    );

    let control = (showTooltipForLabel && selectedOption)
      ? (
        <Tooltip title={selectedOption.label}>
          {formControl}
        </Tooltip>
      )
      : formControl;

    const formClassNames = classNames(classes.root, (useMiniStyle) ? classes.miniRoot : undefined);

    return (
      <form className={formClassNames} autoComplete="off">
        {control}
      </form>
    );
  }
}

SelectControl.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
  useMiniStyle: PropTypes.bool,
  hideEmpty: PropTypes.bool,
  emptyLabel: PropTypes.string,
};

export default withStyles(styles)(SelectControl);