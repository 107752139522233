import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import RichTextEditor from './RichTextEditor';
import AsyncSelectControl from './AsyncSelectControl';
import SelectControl from './SelectControl';
import TaskChecklist from './TaskChecklist';
// import NumericTextField from './NumericTextField';

import NumericTextField from '../Components/NumericTextField';
import {
  GetFormattedDescription,
} from '../Util/Tasks';
import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
} from '../Util/Tags';
import { GetRichTextEditorStyles } from '../Util/RichTextEditor';

import { IsMobile } from '../Util/MobileDetector';
import debounce from 'es6-promise-debounce';

import API, {
  GetTaskMilestonesPathForApi,
  GetTaskStatesPathForApi,
} from '../Util/api';
import {
  GetTaskMilestonesPromise,
} from '../Util/TaskMilestones';
import {
  GetNewTaskMilestone,
} from '../Model/TaskMilestone';
import {
  GetTaskStatesPromise,
} from '../Util/TaskStates';
import {
  GetNewTaskState,
} from '../Model/TaskState';

import TaskAssignmentControl from './TaskAssignmentControl';
import TaskDueOnControl from './TaskDueOnControl';
import TaskPriorityControl from './TaskPriorityControl';

const getDueInMultiplierOptions = () => {
  let options = [];
  for (let i = 1; i <= 100; i++) {
    options.push({ value: i, label: i.toString() });
  }
  return options;
}
const hourMultiplierOption = multiplier => { return { value: "hour", label: `Hour${multiplier > 1 ? "s" : ""}` }};
const dayMultiplierOption = multiplier => { return { value: "day", label: `Day${multiplier > 1 ? "s" : ""}` }};
const weekMultiplierOption = multiplier => { return { value: "week", label: `Week${multiplier > 1 ? "s" : ""}` }};
const getDueInIntervalOptions = multiplier => [
  dayMultiplierOption(multiplier),
  weekMultiplierOption(multiplier),
];
const getDurationIntervalOptions = multiplier => multiplier ? [
  hourMultiplierOption(multiplier),
  dayMultiplierOption(multiplier),
  weekMultiplierOption(multiplier),
] : [];

const styles = theme => ({
  TaskDescription: {
    ...GetRichTextEditorStyles(theme),
  },
  descriptionAndChecklist: {
    flexGrow:1,
    overflow:(IsMobile()) ? undefined : "auto",
    marginTop:theme.spacing(1),
  },
  descriptionAndChecklistContainer: {
    display:"flex",
    height:"100%",
    overflow:"hidden",
  },
  descriptionContainer: {
    flexGrow:1,
    minWidth:"60%",
    maxWidth:"60%",
  },
  description: {
    fontSize:16,
    marginTop:theme.spacing(2),
  },
  tagsAndChecklistContainer: {
    marginLeft:4,
    marginTop: theme.spacing(6),
    width:"100%",
    display:"flex",
    flexDirection:"column",
    overflowY:"auto",
  },
  tagsContainer: {
    marginLeft:theme.spacing(2),
    marginBottom:theme.spacing(3),
    paddingTop:theme.spacing(1),
  },
});

class TaskDialogGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ScrollTop: 0,
      TaskMilestones: [],
      TaskStates: [],
      TagListValues: GetTagListValuesFromTagsObject(this.props.Task),
    }

    this.FinalChecklistItemRef = React.createRef();
  }

  handleDueInMultiplierChange = value => {
    if (!value) {
      value = 0;
    }
    this.props.onNumberChange("DueInMultiplier")(value);
    if (!value) {
      this.handleDueInIntervalChange("");
    } else if (!this.state.DueInInterval) {
      this.handleDueInIntervalChange("week");
    }
  }

  handleDueInIntervalChange = value => {
    this.props.onStringChange("DueInInterval")(value);
  }

  handleDurationIntervalChange = value => {
    this.props.onDurationChange(this.props.Task.DurationMultiplier, value);
  }

  handleDurationMultiplierChange = e => {
    const multiplier = (e && e.target && e.target.value) || "0";
    const interval = (!multiplier)
      ? ""
      : this.props.Task.DurationInterval || "day";
    this.props.onDurationChange(multiplier, interval);
  }

  isDurationMultiplierAllowed = values => {
    const { floatValue } = values;
    return !floatValue || floatValue <= 99;
  }

  handlePriorityChange = value => {
    if (value === undefined || value === null) {
      value = 0;
    }
    this.props.onNumberChange("Priority")(value);
  }

  handleMilestoneChange = selectedOption => {
    let secondaryName, secondaryValue;
    if (selectedOption === undefined || selectedOption === null) {
      selectedOption = { label: "", value: "" };
    } else {
      const taskMilestoneFinder = this.state.TaskMilestones.filter(tm => tm.ID === selectedOption.value);
      if (taskMilestoneFinder.length) {
        secondaryName = "TaskMilestoneName";
        secondaryValue = taskMilestoneFinder[0].Name;
      }
    }
    this.props.onStringChange("TaskMilestoneID", false, secondaryName, secondaryValue)(selectedOption.value);
    if (this.props.onMilestoneValueChange) {
      this.props.onMilestoneValueChange(selectedOption);
    }
  }

  handleMilestoneCreateOption = Name => {
    if (!Name) {
      return;
    }
    Name = Name.trim();

    let TaskMilestones = [...this.state.TaskMilestones];
    const newMilestone = GetNewTaskMilestone(Name);
    API.post(GetTaskMilestonesPathForApi(this.props.organizationId, this.props.projectId), [newMilestone])
      .then(resp => {
        if (resp.data && resp.data.length) {
          const serverMilestone = resp.data[0];
          TaskMilestones.push(serverMilestone);
          this.setState({TaskMilestones});
          this.handleMilestoneChange({value:serverMilestone.ID,label:serverMilestone.Name});
          if (this.props.onTaskMilestoneCreated) {
            this.props.onTaskMilestoneCreated(serverMilestone);
          }
        }
      })
      .catch(this.handleApiError);
  }

  handleStateChange = selectedOption => {
    let secondaryName, secondaryValue;
    if (selectedOption === undefined || selectedOption === null) {
      selectedOption = { label: "", value: "" };
    } else {
      const taskStateFinder = this.state.TaskStates.filter(tm => tm.ID === selectedOption.value);
      if (taskStateFinder.length) {
        secondaryName = "TaskStateName";
        secondaryValue = taskStateFinder[0].Name;
      }
    }
    this.props.onStringChange("TaskStateID", false, secondaryName, secondaryValue)(selectedOption.value);
    if (this.props.onStateValueChange) {
      this.props.onStateValueChange(selectedOption);
    }
  }

  handleStateCreateOption = Name => {
    if (!Name) {
      return;
    }
    Name = Name.trim();

    let TaskStates = [...this.state.TaskStates];
    const newState = GetNewTaskState(Name);
    API.post(GetTaskStatesPathForApi(this.props.organizationId, this.props.projectId), [newState])
      .then(resp => {
        if (resp.data && resp.data.length) {
          const serverState = resp.data[0];
          TaskStates.push(serverState);
          this.setState({TaskStates});
          this.handleStateChange({value:serverState.ID,label:serverState.Name});
          if (this.props.onTaskStateCreated) {
            this.props.onTaskStateCreated(serverState);
          }
        }
      })
      .catch(this.handleApiError);
  }

  handleRichTextEditorChange = (stateAsJsonString, stateAsHtml, stateAsPlainText) => {
    this.props.onRichTextChange(
      "DescriptionRichTextJson", stateAsJsonString, 
      "DescriptionHtml", stateAsHtml,
      "Description", stateAsPlainText,
    );
  }

  handleLoadMilestonesAndReturnOptionsPromise = debounce(nameFilter => {
    if (!this.props.Task) {
      return null;
    }
    return GetTaskMilestonesPromise(this.props.organizationId, this.props.projectId,
      this.props.Task.ID, true, 
      (this.props.isWorkspace && !this.props.workspaceApprovalId),
      (this.props.isWorkspace && Boolean(this.props.workspaceApprovalId)),
      this.props.workspaceApprovalId,
    )
      .then(taskMilestoneList => {
        const TaskMilestones = taskMilestoneList.TaskMilestones;
        this.setState({TaskMilestones});
        if (TaskMilestones && TaskMilestones.length) {
          return TaskMilestones.map(tm => {
            return { label: tm.Name, value: tm.ID };
          });
        } else {
          return null;
        }
      })
      .catch(this.props.onApiError);
  }, 250)

  handleLoadStatesAndReturnOptionsPromise = debounce(nameFilter => {
    if (!this.props.Task) {
      return null;
    }
    return GetTaskStatesPromise(this.props.organizationId, this.props.projectId,
      this.props.Task.ID, true, 
      (this.props.isWorkspace && !this.props.workspaceApprovalId),
      (this.props.isWorkspace && Boolean(this.props.workspaceApprovalId)),
      this.props.workspaceApprovalId,
    )
      .then(taskStateList => {
        const TaskStates = taskStateList.TaskStates;
        this.setState({TaskStates});
        if (TaskStates && TaskStates.length) {
          return TaskStates.map(ts => {
            return { label: ts.Name, value: ts.ID };
          });
        } else {
          return null;
        }
      })
      .catch(this.props.onApiError);
  }, 250)

  render() {
    const {
      ScrollTop,
      TagListValues,
    } = this.state;
    const {
      theme,
      classes,
      organizationId,
      projectId,
      Task,
      workspaceApprovalId,
      additionalTaskPropertiesForCreation,
      onStringChange,
      onSubTaskAdded,
      onSubTaskChanged,
      onSubTaskAddedAfter,
      onStartMoveSubTask,
      onMoveSubTask,
      onAbortMoveSubTask,
      onEndMoveSubTask,
      hideName,
      showDueOn,
      showDueIn,
      isWorkflow,
      isCreateNew,
      isWorkspace,
      isProjectAdmin,
      isUserRestrictedToAssignedTasks,
      onTagListValuesChanged,
      onApiError,
      onAssignmentValueChange,
      onAssignmentCreateOption,
      onDateTimeChange,
      onNumberChange,
    } = this.props;

    const taskIsInactive = (!isCreateNew && !isWorkflow && Task && Task.Result);

    const nameGridItem = (!hideName)
      ? (
        <Grid item key="name" xs={12} lg={6} xl={4}>
          <TextField
            variant="outlined"
            label="Name"
            value={(Task && Task.Name) || ""}
            autoFocus={isCreateNew}
            onChange={onStringChange("Name")}
            fullWidth
            disabled={taskIsInactive || isWorkspace}
          />
        </Grid>
      ) : null;

    const dueOnGridItems = (showDueOn && (!isWorkspace || Task.DueOn))
      ? (
        <Grid item key="dueOn" xs={12} lg={6} xl={4}>
          <TaskDueOnControl
            task={Task}
            isWorkspace={isWorkspace}
            onDateTimeChange={onDateTimeChange}
          />
        </Grid>
      )
      : null;

    const dueInGridItems = (showDueIn)
      ? [
        <Grid item key="dueInMultiplier" xs={7} md={(isWorkflow) ? 2 : 6} lg={(isWorkflow) ? 1 : 3} style={{paddingRight:0}}>
          <SelectControl
            id="select_dueInMultiplier"
            label="Due In"
            forceShrinkLabel
            options={getDueInMultiplierOptions()}
            value={(Task.DueInMultiplier) ? Task.DueInMultiplier : null}
            onValueChange={this.handleDueInMultiplierChange}
          />
        </Grid>,
        <Grid item key="dueInInterval" xs={5} md={(isWorkflow) ? 2 : 6} lg={(isWorkflow) ? 3 : 3}>
          <SelectControl
            id="select_dueInInterval"
            forceShrinkLabel
            hideEmpty
            options={getDueInIntervalOptions(Task.DueInMultiplier)}
            disabled={!Task.DueInMultiplier} 
            value={Task.DueInInterval}
            onValueChange={this.handleDueInIntervalChange}
          />
        </Grid>
      ]
      : null;

    const durationGridItems = [
      <Grid item key="durationMultiplier" xs={4} md={(isWorkflow) ? 2 : 4} lg={2} xl={1} style={{paddingRight:0}}>
        <NumericTextField
          id="durationMultiplier"
          label="Duration"
          value={(Task && Task.DurationMultiplier && Task.DurationMultiplier.toString()) || ""}
          onValueChange={this.handleDurationMultiplierChange}
          isAllowedFunc={this.isDurationMultiplierAllowed}
          decimalPlaces={2}
          forceShrinkLabel
          disabled={taskIsInactive || isWorkspace}
        />
      </Grid>,
      <Grid item key="durationInterval" xs={8} md={(isWorkflow) ? 2 : 8} lg={2} xl={3}>
        <SelectControl
          id="select_durationInterval"
          forceShrinkLabel
          hideNone
          options={getDurationIntervalOptions(Task && Task.DurationMultiplier)}
          disabled={(Task && !Task.DurationMultiplier) || taskIsInactive || isWorkspace} 
          value={(Task && Task.DurationMultiplier) ? Task.DurationInterval : null}
          onValueChange={this.handleDurationIntervalChange}
        />
      </Grid>
    ];

    const priorityGridItem = (Task && (!isWorkspace || Task.Priority > 0)) ? (
      <Grid item key="priority" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 6} xl={(isWorkflow) ? 4 : 2}>
        <TaskPriorityControl
          task={Task}
          isWorkspace={isWorkspace}
          isWorkflow={isWorkflow}
          additionalTaskPropertiesForCreation={additionalTaskPropertiesForCreation}
          onPriorityChange={onNumberChange("Priority")}
        />
      </Grid>
    ) : null;

    const milestoneGridItem = (Task && (!isWorkspace || Task.TaskMilestoneID !== "")) ? (
      <Grid item key="milestone" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 4} xl={(isWorkflow) ? 4 : 3}>
        <AsyncSelectControl label="Milestone" 
          // forceShrinkLabel
          noFlexWrap // This prevents a gap/blank link at the bottom of the value container
          floatingOptions
          onGetOptionsFilterPromise={this.handleLoadMilestonesAndReturnOptionsPromise}
          listValues={
            (Task && Task.TaskMilestoneID)
              ? {
                label: Task.TaskMilestoneName,
                value: Task.TaskMilestoneID,
              }
              : null
          }
          onValueChange={this.handleMilestoneChange}
          onCreateOption={(isProjectAdmin || isWorkflow) ? this.handleMilestoneCreateOption : undefined}
          autoReloadOnValueChange
          disabled={taskIsInactive || isWorkspace
            || (
              (additionalTaskPropertiesForCreation)
                ? additionalTaskPropertiesForCreation.TaskMilestoneID !== ""
                : false
            )
          }
        />
      </Grid>
    ) : null;

    const stateGridItem = (Task && (!isWorkspace || Task.TaskStateID !== "")) ? (
      <Grid item key="state" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 4} xl={(isWorkflow) ? 4 : 3}>
        <AsyncSelectControl label="Task State" 
          // forceShrinkLabel
          noFlexWrap // This prevents a gap/blank link at the bottom of the value container
          floatingOptions
          onGetOptionsFilterPromise={this.handleLoadStatesAndReturnOptionsPromise}
          listValues={
            (Task && Task.TaskStateID)
              ? {
                label: Task.TaskStateName,
                value: Task.TaskStateID,
              }
              : null
          }
          onValueChange={this.handleStateChange}
          onCreateOption={(isProjectAdmin || isWorkflow) ? this.handleStateCreateOption : undefined}
          autoReloadOnValueChange
          disabled={taskIsInactive || isWorkspace
            || (
              (additionalTaskPropertiesForCreation)
                ? additionalTaskPropertiesForCreation.TaskStateID !== ""
                : false
            )
          }
        />
      </Grid>
    ) : null;

    const description = (!taskIsInactive)
      ? (
        <RichTextEditor
          initialStateJson={Task && Task.DescriptionRichTextJson}
          initialStatePlainText={Task && Task.Description}
          parentScrollTop={ScrollTop}
          onChange={this.handleRichTextEditorChange}
          onApiError={onApiError}
          label="Description"
        />
      )
      : (
        <div className={classes.description}>
          {GetFormattedDescription(Task, theme, classes)}
        </div>
      );

    const assignmentDisabled = taskIsInactive
      || isWorkspace
      || (isUserRestrictedToAssignedTasks && !isProjectAdmin)
      || (additionalTaskPropertiesForCreation
        && additionalTaskPropertiesForCreation.AssignmentUserEmail
        && additionalTaskPropertiesForCreation.AssignmentUserEmail !== "");
    const assignmentGridItemInternal = (
      <Grid item key="assignment" xs={12} md={(isWorkflow) ? 4 : undefined} lg={(isWorkflow) ? 4 : 6} xl={4}>
        <TaskAssignmentControl
          task={Task}
          isWorkspace={isWorkspace}
          isWorkflow={isWorkflow}
          isProjectAdmin={isProjectAdmin}
          isUserRestrictedToAssignedTasks={isUserRestrictedToAssignedTasks}
          additionalTaskPropertiesForCreation={additionalTaskPropertiesForCreation}
          onAssignmentValueChange={onAssignmentValueChange}
          onAssignmentCreateOption={onAssignmentCreateOption}
          onApiError={onApiError}
          organizationId={organizationId}
          projectId={projectId}
        />
      </Grid>
    );
    const assignmentGridItem = (isWorkspace && assignmentDisabled)
      ? (
        <Tooltip title="Assignments cannot be changed in My Work.">
          {assignmentGridItemInternal}
        </Tooltip>
      )
      : assignmentGridItemInternal;

    const tagsControl = GetTagsControl(organizationId, projectId, (Task) ? Task.ID : null, 
      (isWorkspace && !workspaceApprovalId),
      (isWorkspace && Boolean(workspaceApprovalId)), workspaceApprovalId, true,
      TagListValues, TagListValues => this.setState({TagListValues}),
      onTagListValuesChanged, (Task && Boolean(Task.Result)), -1, null, !isWorkflow);

    const taskChecklist = (Task)
      ? (
        <TaskChecklist
          Task={Task}
          onSubTaskAdded={onSubTaskAdded}
          onSubTaskChanged={onSubTaskChanged}
          onSubTaskAddedAfter={onSubTaskAddedAfter}
          onStartMoveSubTask={onStartMoveSubTask}
          onMoveSubTask={onMoveSubTask}
          onAbortMoveSubTask={onAbortMoveSubTask}
          onEndMoveSubTask={onEndMoveSubTask}
          disabled={taskIsInactive}
        />
      ) : null;

    const desktopDescriptionAndTagsAndChecklist = (!IsMobile())
      ? (
        <div className={classes.descriptionAndChecklist}>
          <div className={classes.descriptionAndChecklistContainer}>
            <div className={classes.descriptionContainer}>
              {description}
            </div>
            <div className={classes.tagsAndChecklistContainer}>
              <div className={classes.tagsContainer}>
                {tagsControl}
              </div>
              {taskChecklist}
            </div>
          </div>
        </div>
      ) : null;

    const mobileDescription = (IsMobile())
      ? ( 
        <div style={{marginTop:theme.spacing(1)}}>
          {description}
        </div>
      ) : null;

    return (
      <div
        style={{
          display:"flex",
          flexDirection:"column",
          height:"100%",
          overflowY: (IsMobile()) ? "auto" : undefined,
          overflowX: "hidden",
          // This prevents the horizontal scrollbar from showing due to material ui negative margin
          // overflowX:"hidden",
          paddingTop:theme.spacing(1),
        }}
        onScroll={e => this.setState({ScrollTop: e.target.scrollTop})}
      >
        <div>
          <Grid container spacing={2}>
            {nameGridItem}
            {assignmentGridItem}
            {dueOnGridItems}
            {dueInGridItems}
            {priorityGridItem}
            {milestoneGridItem}
            {stateGridItem}
            {durationGridItems}
          </Grid>
        </div>

        {desktopDescriptionAndTagsAndChecklist}
        {mobileDescription}
      </div>
    );
  }
}

TaskDialogGrid.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  Task: PropTypes.object,
  additionalTaskPropertiesForCreation: PropTypes.object,
  onStringChange: PropTypes.func.isRequired,
  onRichTextChange: PropTypes.func.isRequired,
  onNumberChange: PropTypes.func.isRequired,
  onDateTimeChange: PropTypes.func.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  onAssignmentValueChange: PropTypes.func.isRequired,
  onAssignmentCreateOption: PropTypes.func,
  onMilestoneValueChange: PropTypes.func,
  onStateValueChange: PropTypes.func,
  onTagListValuesChanged: PropTypes.func,
  onSubTaskAdded: PropTypes.func.isRequired,
  onSubTaskChanged: PropTypes.func.isRequired,
  onSubTaskAddedAfter: PropTypes.func.isRequired,
  onStartMoveSubTask: PropTypes.func.isRequired,
  onMoveSubTask: PropTypes.func.isRequired,
  onAbortMoveSubTask: PropTypes.func.isRequired,
  onEndMoveSubTask: PropTypes.func.isRequired,
  onTaskMilestoneCreated: PropTypes.func,
  onTaskStateCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  showDueOn: PropTypes.bool,
  showDueIn: PropTypes.bool,
  isWorkspace: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  isUserRestrictedToAssignedTasks: PropTypes.bool,
  workspaceApprovalId: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(TaskDialogGrid);