import { GetFieldForFilterAndSort } from '../Util/Field';
import {
  TextFieldOperators,
} from '../Util/Search';
import {
	GetTaskWithAssignmentOption,
} from '../Util/Tasks';

import TaskAssignmentControl from '../Components/TaskAssignmentControl';
import TaskDueOnControl from '../Components/TaskDueOnControl';
import TaskPriorityControl from '../Components/TaskPriorityControl';

import {
  //GetDateValue,
  GetUserValue,
} from '../Util/Properties';

export const GetAdvancedSearchResultsCollectionFieldsByContentType = (contentType, organizationId, projectId,
	theme, onApiError, onCellValueChange) => {
	
	let filterSortFields = [
		{
			...GetFieldForFilterAndSort(
				"Meta_kw1500[id].keyword",
				"ID",
				"FieldType_Text",
				"Meta_kw1500[id]",
			),
			ItemField: "ID",
		},	
	];
	if (contentType !== "AssetItem") {
		let label = "Name";
		switch (contentType) {
		case "Document":
			label = "Document name";
			break;
		case "Task":
			label = "Task name";
			break;
		case "FormTemplate":
			label = "Form name";
			break;
		default:
			label = "Name";
			break;
		}
		filterSortFields.push(
			{
				...GetFieldForFilterAndSort(
					"Meta_text_kw256lc[Name].keyword",
					label,
					"FieldType_Text",
					"Meta_text_kw256lc[Name]",
				),
				ItemField: "Name",
				Editable: params => true,
			},
		);
	}
	if (contentType === "Task") {
		filterSortFields.push(
			{
				...GetFieldForFilterAndSort(
					"Meta_text_kw256lc[AssignmentUserEmail].keyword",
					"Assigned to",
					"FieldType_Text",
					null,
				    [
				      {
				          value: "equals",
				          label: "equals",
				      },
				      {
				        value: "notEquals",
				        label: "does not equal",
				      },
				    ],
				    "addressBookItemsList",
				    true,
				),
				ItemField: "AssignmentUserEmail",
				Editable: params => true,
				//CellDataType: "user",
				CellRenderer: props => GetUserValue(props.data.AssignmentUserEmail, props.data.AssignmentUserName),
				CellEditorSelector: outerParams => {
					return {
						component: innerParams => {
							// console.log("outerParams", outerParams);
							// console.log("innerParams", innerParams);
							return (
								<TaskAssignmentControl
									task={innerParams.data}
				          onAssignmentValueChange={selectedOption => {
				          	// console.log(innerParams);
				          	const updatedTask = GetTaskWithAssignmentOption(selectedOption, {...innerParams.data});
				          	// The following are required to change the grid cell content successfully
				          	outerParams.data.AssignmentUserEmail = updatedTask.AssignmentUserEmail;
				          	outerParams.data.AssignmentUserName = updatedTask.AssignmentUserName;
				          	innerParams.onValueChange(updatedTask.AssignmentUserEmail);
				            // The following updates state and back-end immediately
				          	return onCellValueChange(innerParams, "AssignmentUserEmail", updatedTask);
				          }}
				          forcedBackgroundColor={theme.palette.background.pane}
				          onApiError={onApiError}
				          organizationId={organizationId}
				          projectId={projectId}
								/>
							);
						}
					};
				},
			},
			{
				...GetFieldForFilterAndSort(
					"Meta_date_str256[DueOn].string",
					"Due On",
					"FieldType_Date",
				),
				ItemField: "DueOn",
				Editable: params => true,
				CellDataType: "dateAndTime",
				CellEditorSelector: outerParams => {
					return {
						component: innerParams => {
							// console.log("outerParams", outerParams);
							// console.log("innerParams", innerParams);
							return (
								<TaskDueOnControl
			            task={innerParams.data}
			            forcedBackgroundColor={theme.palette.background.pane}
			            onDateTimeChange={(propertyName, dateTime) => {
			            	// console.log("onDateTimeChange", innerParams);
			            	const updatedTask = {
			            		...innerParams.data,
			            		DueOn: dateTime || null,
			            	};
				          	// console.log(updatedTask.DueOn);
			            	// The following are required to change the grid cell content successfully
			            	outerParams.data.DueOn = updatedTask.DueOn;
				          	innerParams.onValueChange(updatedTask.DueOn);
				          	// The following updates state and back-end immediately
			            	return onCellValueChange(innerParams, "DueOn", updatedTask);
			            }}
			          />
							);
						}
					};
				},
			},
			// {
			// 	...GetFieldForFilterAndSort(
			// 		"Meta_long[Duration]",
			// 		"Duration",
			// 		"FieldType_Number",
			// 		null,
			// 		null,
			// 		null,
			// 		false,
			// 		true,
			// 	),
			// 	ItemField: "Duration",
			// },
			{
				...GetFieldForFilterAndSort(
					"Meta_text_kw256lc[MilestoneName].keyword",
					"Milestone",
					"FieldType_Text",
					null,
					[
				      {
				          value: "equals",
				          label: "equals",
				      },
				      {
				      	value: "notEquals",
				      	label: "does not equal",
				      },
				    ],
				  	"taskMilestones",
				  	false,
				  	false,
				  	null,
				  	"Meta_text_kw256lc[MilestoneName]",
				  	true,
				  	TextFieldOperators,
				),
				ItemField: "TaskMilestoneName",
			},
			{
				...GetFieldForFilterAndSort(
					"Meta_int[Priority]",
					"Priority",
					"FieldType_Text", // We use a text field internally, but use FieldType_Number for API queries
					null,
					[
						{
						    value: "equals",
						    label: "equals",
						},
					],
			    	"taskPriorityTypes",
			    	false,
			    	false,
			    	"FieldType_Number", // We use a text field internally, but use FieldType_Number for API queries
				),
				ItemField: "Priority",
				Editable: params => true,
				CellDataType: "taskPriority",
				CellEditorSelector: outerParams => {
					return {
						component: innerParams => {
							// console.log("outerParams", outerParams);
							// console.log("innerParams", innerParams);
							return (
								<TaskPriorityControl
				          task={innerParams.data}
				          forcedBackgroundColor={theme.palette.background.pane}
				          useAsyncSelect
				          onPriorityChange={eventOrValue => {
				          	// console.log("onPriorityChange", innerParams);
			            	const updatedTask = {
			            		...innerParams.data,
			            		Priority: eventOrValue || null,
			            	};
				          	// console.log(updatedTask.Priority);
			            	// The following are required to change the grid cell content successfully
			            	outerParams.data.Priority = updatedTask.Priority;
				          	innerParams.onValueChange(updatedTask.Priority);
				          	// The following updates state and back-end immediately
			            	return onCellValueChange(innerParams, "Priority", updatedTask);
				          }}
				        />
							);
						}
					};
				},
			},
			{
				...GetFieldForFilterAndSort(
					"Meta_text_kw256lc[StateName].keyword",
					"Task State",
					"FieldType_Text",
					null,
					[
				      {
				          value: "equals",
				          label: "equals",
				      },
				      {
				      	value: "notEquals",
				      	label: "does not equal",
				      },
				    ],
			    	"taskStates",
			    	false,
			    	false,
			    	null,
			    	"Meta_text_kw256lc[StateName]",
			    	true,
			    	TextFieldOperators,
				),
				ItemField: "TaskStateName",
			},
		);
	}
	
	filterSortFields.push(
		{
			...GetFieldForFilterAndSort(
				"Meta_text_kw50lc[Tag].keyword",
				"Tags",
				"FieldType_Text",
				"Meta_text_kw50lc[Tag]",
				null,
		  	"tags",
		  	true,
		  	false,
		  	null,
		  	null,
		  	false,
		  	null,
		  	true,
			),
			ItemField: "Tags",
		},
	);

	return filterSortFields;
}