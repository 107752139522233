import React from 'react';
import PropTypes from 'prop-types';
import SelectControl from './SelectControl';
import TaskPriorityType from '../Model/TaskPriorityType';

import AsyncSelectControl from '../Components/AsyncSelectControl';

import {
  GetTaskPriorityLabel,
} from '../Util/Task';

const TaskPriorityControl = (
  { task,
    isWorkspace,
    isWorkflow,
    additionalTaskPropertiesForCreation,
    onPriorityChange,
    forcedBackgroundColor,
    useAsyncSelect,
  }) => {
  const getPriorityOptions = () => {
    return TaskPriorityType.map(p => ({
      label: p.Label,
      value: p.Type,
    }));
  };

  const getPriorityOptionsPromise = filter => {
    const options = getPriorityOptions();
    if (!filter) {
      return Promise.resolve(options);
    }
    return Promise.resolve(options.filter(o => o.toLowerCase().startsWith(filter.toLowerCase())));
  }

  const handlePriorityChange = value => {
    if (value === undefined || value === null) {
        value = 0;
      }
    if (typeof value === "object") {
      if (value.value === undefined || value.value === null) {
        value.value = 0;
      }
      onPriorityChange(value.value);
    } else {
      onPriorityChange(value);
    }
  };

  if (!task || (isWorkspace && task.Priority <= 0)) {
    return null;
  }

  const taskIsInactive = (task && !task.isCreateNew && !task.isWorkflow && task.Result);

  const isPriorityDisabled = taskIsInactive || isWorkspace || (
    additionalTaskPropertiesForCreation &&
    additionalTaskPropertiesForCreation.Priority > -1
  );

  if (useAsyncSelect) {
    return (
      <AsyncSelectControl
        label="Priority"
        forceShrinkLabel
        hideEmpty
        onGetOptionsFilterPromise={getPriorityOptionsPromise}
        listValues={{value: task.Priority, label: GetTaskPriorityLabel(task.Priority)}}
        onValueChange={handlePriorityChange}
        disabled={isPriorityDisabled}
        style={{
          backgroundColor: forcedBackgroundColor,
        }}
      />
    );
  } else {
    return (
      <SelectControl
        id="select_priority"
        label="Priority"
        forceShrinkLabel
        hideEmpty
        options={getPriorityOptions()}
        value={task.Priority}
        onValueChange={handlePriorityChange}
        disabled={isPriorityDisabled}
        style={{
          backgroundColor: forcedBackgroundColor,
        }}
      />
    );
  }
};

TaskPriorityControl.propTypes = {
  task: PropTypes.object.isRequired,
  isWorkspace: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  taskIsInactive: PropTypes.bool,
  additionalTaskPropertiesForCreation: PropTypes.object,
  onPriorityChange: PropTypes.func.isRequired,
};

export default TaskPriorityControl;
