import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import { GetDueOnStyle } from '../Util/Tasks';
import dateformat from 'dateformat';

const TaskDueOnControl = ({ task, isWorkspace, onDateTimeChange, forcedBackgroundColor }) => {
  const [dueOnDateString, setDueOnDateString] = useState(() => {
    if (task && task.DueOn) {
      const dueOnDate = new Date(task.DueOn);
      return dueOnDate > new Date("2000-01-01") ? dateformat(dueOnDate, "yyyy-mm-dd") : "";
    }
    return "";
  });

  const [dueOnTimeString, setDueOnTimeString] = useState(() => {
    if (task && task.DueOn) {
      const dueOnDate = new Date(task.DueOn);
      return dueOnDate > new Date("2000-01-01") ? dateformat(dueOnDate, "HH:MM") : "";
    }
    return "";
  });

  const updateTaskDueOn = (dateString, timeString) => {
    let dueOn = null;
    if (dateString) {
      dueOn = new Date(`${dateString}T${timeString || "12:00"}:00`);  
    }
    onDateTimeChange("DueOn", dueOn);
  };

  const clearDueOn = () => {
    setDueOnDateString("");
    setDueOnTimeString("");
    updateTaskDueOn();
  };

  const handleDueOnDateChange = (e) => {
    if (!e || !e.target || !e.target.value) {
      return clearDueOn();
    }
    const newDateString = e.target.value;
    setDueOnDateString(newDateString);
    let newTimeString = dueOnTimeString;
    if (!newTimeString) {
      newTimeString = "12:00";
      setDueOnTimeString(newTimeString);
    }
    updateTaskDueOn(newDateString, newTimeString);
  };

  const handleDueOnTimeChange = (e) => {
    const newTimeString = e.target.value;
    setDueOnTimeString(newTimeString);
    updateTaskDueOn(dueOnDateString, newTimeString);
  };

  const getClearDueOnDateInputAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        tabIndex={-1}
        edge="end"
        aria-label="clear"
        onClick={() => handleDueOnDateChange({ target: { value: "" } })}
      >
        <ClearIcon style={{ fontSize: 18 }} />
      </IconButton>
    </InputAdornment>
  );

  const taskIsInactive = (task && !task.isCreateNew && !task.isWorkflow && task.Result);

  return (
    <Grid container spacing={1} style={{minWidth:335}}>
      <Grid item key="dueOnDate" xs={7}>
        <TextField
          variant="outlined"
          label="Due On"
          value={dueOnDateString}
          onChange={handleDueOnDateChange}
          fullWidth
          type="date"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            max: "9999-12-31",
            style: (task && !task.Result) ? GetDueOnStyle(task.DueOn) : undefined,
          }}
          style={{
            backgroundColor: forcedBackgroundColor,
          }}
          InputProps={{
            endAdornment: (!taskIsInactive && !isWorkspace && dueOnDateString) ? getClearDueOnDateInputAdornment() : undefined,
          }}
          disabled={taskIsInactive || isWorkspace}
        />
      </Grid>
      <Grid item key="dueOnTime" xs={5}>
        <TextField
          variant="outlined"
          value={dueOnTimeString}
          onChange={handleDueOnTimeChange}
          fullWidth
          disabled={!dueOnDateString || taskIsInactive || isWorkspace}
          type="time"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            style: (task && !task.Result) ? GetDueOnStyle(task.DueOn) : undefined,
          }}
          style={{
            backgroundColor: forcedBackgroundColor,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TaskDueOnControl;
